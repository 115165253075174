<template>
  <div class="booking-form desktop inlay">
    <div class="flex">
      <div class="booking-form-tab text-base bg-primary-red active text-primary-white py-gap px-6 font-normal mr-2 tracking-wide">
        {{ entry.accommodationTabTitle }}
      </div>
      <!--      probably doesn't work-->
      <!--      <div id="booking-tab-baths"-->
      <!--           class="booking-form-tab text-base bg-primary-red text-primary-white py-gap px-6 font-normal mr-2 tracking-wide open-ticket-form">-->
      <!--        {{ entry.bathsTabTitle }}-->
      <!--      </div>-->
      <a :href="entry.shopTabUrl"
         class="booking-form-tab text-base bg-primary-red text-primary-white py-gap px-6 font-normal mr-2 tracking-wide">
        {{ entry.shopTabTitle }}
      </a>
    </div>
    <v-app>
      <div class="flex relative">
        <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="bookingForm.date"
            min-width="fit-content"
            offset-y
            tile
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="booking-input-wrapper min-w-40%">
              <div>{{ translations['FROM'] }} - {{ translations['TO'] }}</div>
              <div class="flex justify-between gap-x-6 items-center">
                <div class="flex">
                  <div class="booking-input-value">
                    {{ getWeekDay(bookingForm.date[0]) + ', ' + formatDate(bookingForm.date[0]) }}
                  </div>
                  <span v-if="bookingForm.date[1]" class="text-lg text-tertiary-gray-darken-1 ml-2"> - </span>
                  <div v-if="bookingForm.date[1]" class="booking-input-value ml-2">
                    {{ getWeekDay(bookingForm.date[1]) + ', ' + formatDate(bookingForm.date[1]) }}
                  </div>
                </div>
                <div class="booking-form-button" :class="{ open: menuDate }"></div>
              </div>
            </div>
          </template>
          <v-date-picker
              v-model="bookingForm.date"
              :allowed-dates="allowedDates"
              no-title
              scrollable
              range
              show-adjacent-months
              :locale="language"
              @change="$refs.menuDate.save(bookingForm.date)"
              class="booking-menu"
          />
        </v-menu>
        <v-menu
            ref="menuGuest"
            v-model="menuGuest"
            :close-on-content-click="false"
            offset-y
            tile
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="booking-input-wrapper flex-grow">
              <div>{{ translations['GUESTS'] }}</div>
              <div class="flex justify-between items-center">
                <div class="booking-input-value">
                  {{ bookingForm.adults }} {{ translations[bookingForm.adults === 1 ? 'ADULT' : 'ADULTS'] }},
                  {{ bookingForm.amountChildren }}
                  {{ translations[bookingForm.amountChildren === 1 ? 'CHILD' : 'CHILDREN'] }}
                </div>
                <div class="booking-form-button" :class="{ open: menuGuest }"></div>
              </div>
            </div>
          </template>
          <div class="p-6 bg-primary-white booking-menu">
            <div class="flex items-center justify-between booking-form-amount">
              <div class="text-lg">{{ translations['ADULTS'] }}</div>
              <BookingFormAmountInput :amount="bookingForm.adults" :min="1" :max="10"
                                      @amount-updated="amount => updateAmount(amount, 'adults')"></BookingFormAmountInput>
            </div>
            <div class="border-bottom-tertiary-gray my-3"></div>
            <div class="flex items-center justify-between booking-form-amount">
              <div class="text-lg">{{ translations['CHILDREN_WITH_AGE'] }}</div>
              <BookingFormAmountInput :amount="bookingForm.amountChildren" :min="0" :max="6"
                                      @amount-updated="amount => updateAmount(amount, 'children')"></BookingFormAmountInput>
            </div>
            <div v-if="bookingForm.amountChildren > 0" class="border-bottom-tertiary-gray my-3"></div>
            <div class="flex flex-col gap-y-4">
              <div v-for="(n, i) in bookingForm.amountChildren"
                   class="flex items-center justify-between booking-form-amount">
                <label class="text-lg">{{ translations['CHILD_AGE'] }} {{ n }}</label>
                <BookingFormAmountInput :amount="bookingForm.children[i]" :min="0" :max="15"
                                        @amount-updated="amount => updateAmount(amount, 'childAge', i)"></BookingFormAmountInput>
              </div>
            </div>
          </div>
        </v-menu>
        <div>
          <button class="button booking-feratel" @click="accommodationSearch">{{ translations['BOOK_NOW'] }}</button>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import {VApp, VDatePicker} from 'vuetify/lib';
import BookingFormAmountInput from "./BookingFormAmountInput";
import bookingFormService from "../common/services/bookingFormService";

export default {
  name: 'BookingFormDesktop',
  components: {VApp, VDatePicker, BookingFormAmountInput},
  props: ['entry', 'translations', 'language'],
  mixins: [bookingFormService],
  data: () => ({
    bookingForm: {
      date: [],
      adults: 2,
      amountChildren: 0,
      children: []
    },
    menuDate: false,
    menuGuest: false,
    i18nWeekDays: {
      'fr-CH': ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      'en-CH': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      'de-CH': ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    }
  }),
  mounted() {
    const today = new Date();
    let inAWeek = new Date();
    inAWeek.setDate(today.getDate() + 7);
    this.bookingForm.date = [this.getISODate(today), this.getISODate(inAWeek)];
  },
  computed: {},
  methods: {
    getWeekDay(date) {
      return this.i18nWeekDays[this.language][new Date(date).getDay()];
    }
  }
}
</script>